.Partners  {
    position: absolute;
    height: 100vh;
    top: calc(10* 100vh);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > p {
        color: white;
        font-size: 0.8rem;
    }
    > .our-partners {
        background-color: white;
        width: 90%;
        border-radius: 15px;
        margin-bottom: 1vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 2vh;
        padding-bottom: 4vh;
        > .title-ctn {
            display: flex;
            align-items: center;
            margin-bottom: 4vh;
            > img {
                width: 40px;
                height: 40px;
            }
            > h1 {
                font-size: 25px;
                color: #707070;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        > .share-ctn {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 5vh;
            > p {
                font-size: 0.8rem;
                margin: 0;
                margin-top: 1vh;
            }
            > p.link{
                font-weight: 800;
                cursor:pointer;
            }
        }
    }
    > .stella-ctn {
        background-color: #9D5779;
        height: 25vh;
        border-radius: 15px;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 1vh;
        > .title-ctn {
            display: flex;
            justify-content: center;
            flex-direction: row;
            align-items: center;
            > img {
                width: 60px;
                height: 66px;
            }
            > h1 {
                color: #D8A8D3;
                font-size: 2.5rem;
                margin-left: 20px;
            }
        }
        > h2 {
            color: #D8A8D3;
            font-size: 1rem;
            font-weight: 300;
        }
        > img.hubOne {
            width: 30px;
            transform: rotate(45deg);
            position: absolute;
            left: 14px;
            top: 10px;
        }
        > img.hubTwo {
            width: 41px;
            transform: rotate(53deg);
            position: absolute;
            right: 4px;
            bottom: 5px;
        }
    }
}
@media screen and (min-width: 620px) {
    .Partners {
        width: 80%;
        margin-left: 10%;
    }
}
@media screen and (min-width: 1100px) {
    .Partners {
        top: calc(8* 100vh);
        > .our-partners {
            width: 50%;
        }
        > .stella-ctn {
            width: 50%;
        }
    }
}