
.Main {
    width: 100%;
    height: calc(11*100vh);
    background: rgb(242,114,68);
    background: -moz-linear-gradient(237deg, rgba(242,114,68,1) 0%, rgba(217,173,210,1) 25%, rgba(152,210,203,1) 50%, rgba(242,180,126,1) 100%, rgba(130,123,219,1) 100%);
    background: -webkit-linear-gradient(237deg, rgba(242,114,68,1) 0%, rgba(217,173,210,1) 25%, rgba(152,210,203,1) 50%, rgba(242,180,126,1) 100%, rgba(130,123,219,1) 100%);
    background: linear-gradient(237deg, rgba(242,114,68,1) 0%, rgba(217,173,210,1) 25%, rgba(152,210,203,1) 50%, rgba(242,180,126,1) 100%, rgba(130,123,219,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f27244",endColorstr="#827bdb",GradientType=1);
}

@media screen and (min-width: 1100px) {
    .Main {
        height: calc(9* 100vh);
    }
}