.Button {
    background-color: var(--main-green-three);
    border-radius: 15px;
    margin-top: 75vh;
    cursor: pointer;
    position: absolute;
    z-index: 98;
    width: 100%;
    > h1 {
        color: var(--main-white);
        text-align: center;
        font-size: 1.3rem;
        padding-top: 3vh;
        padding-bottom: 3vh;
    }
    
}
@media screen and (min-width:900px) {
    .Button {
        margin-top: 70vh;
        width: 50%;
        left: 0;
        margin-left: 25%;
    }
}
@media screen and (min-width:1100px) {
    .Button {
        margin-top: 75vh;
        width: 100%;
        margin-left: 0%;
    }
}