.Review {
    display: flex;
    justify-content: center;
    width: 100%;
    > .ctn  {
        position: relative;
        height: 25vh;
        margin-top: 5vh;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
            position: absolute;
            transform: rotate(10deg);
            width: 45px;
            margin-left: 225px;
            margin-top: 120px;
        }
        > .first-g {
            position: absolute;
            transform: rotate(12deg);
            margin-right: -200px;
            margin-top: -70px;
            > p {
                font-size: 90px;
                font-weight: 200;
                margin: 0;
                font-family: Arial;
                color: var(--main-violet-one);
            }
        }
        > .second-g {
            position: absolute;
            transform: rotate(192deg);
            margin-top: 110px;
            margin-left: -125px;
            > p {
                font-size: 90px;
                font-weight: 200;
                margin: 0;
                font-family: Arial;
                color: var(--main-violet-one);
            }
        }
        > .text-ctn {
            position: absolute;
            width: 155px;
            height: 120px;
            padding: 5px;
            margin-left: 30px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            > p {
                text-align: center;
                font-size: 14px;
                color:var(--main-green-three);
                font-weight: 600;
            }
        }
        > .violet {
            width:200px;
            height:130px;
            background-color: var(--main-violet-one);
            border-radius: 15px;
            transform: rotate(-6deg);
            position: absolute;
        }
        > .white {
            width: 200px;
            height: 130px;
            background-color: var(--main-white);
            border-radius: 15px;
            transform: rotate(-6deg);
            margin-left: 25px;
            margin-top: 20px;
            position: absolute;
        }
    }
}
@media screen and (min-width:900px) {
    .Review {
        width: 310px;
        > .ctn {
            > .violet {
                width:230px;
                height:160px;
            }
            > .white {
                width:230px;
                height:160px;
            }
            > .first-g {
                right: 170px;
            }
            > img {
                width: 50px;
                right: -45px;
                top: 25px;
            }
            > .second-g {
                top: 5px;
            }
            > .text-ctn {
                margin-left: 40px;
                margin-top: 20px;
            }
        }
    }
    .Review:hover {
        width: 420px;
        > .ctn {
            > .violet {
                width: 280px;
                height: 210px;
            }
            > .white {
                width: 280px;
                height: 210px;
            }
            > .first-g {
                right: 150px;
                top: 45px;
            }
            > img {
                width: 70px;
                right: -100px;
                top: 40px;
            }
            > .second-g {
                left: 110px;
                top: 30px;
            }
            > .text-ctn {
                width: 220px;
                margin-left: 40px;
                margin-top: 20px;
                > p {
                    font-size: 16px;
                }
            }
        }
    }
}