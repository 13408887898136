.Card {
    background-color: var(--main-violet-one);
    border-radius: 15px;
    width: 100%;
    padding-top: 5vh;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    margin-top: 15vh;
    position: absolute;
    z-index: 98;
    > .content-ctn {
        background-color: var(--main-white);
        border-radius: 15px;
        width: calc(100% - 20px);
        > h1 {
            font-weight: 600;
            font-size: 1.4rem;
            text-align: center;
            padding-left: 5%;
            padding-right: 5%;
            color: var(--main-violet-one);
        }
        > p {
            font-weight: 400;
            font-size: 0.9rem;
            text-align: center;
            padding-left: 5%;
            padding-right: 5%;
            color: var(--main-violet-one);
        }
    }
}
@media screen and (min-width: 900px) {
    .Card {
        width: 50%;
    }
}
@media screen and (min-width:1100px) {
    .Card {
        width: 90%;
        height: 48vh;
    }
}