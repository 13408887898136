.Values {
    width: 100%;
    position: absolute;
    top: 600vh;

    > .sub-values-ctn {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;

        > h1 {
            color:var(--main-white);
            text-align: center;
        }

        > .ctn {
            width: 90%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-top: 5vh;

            > .img {
                border-radius: 15px;
                height: 30vh;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% auto;
            }
            > .img.gouvernance {
                background-image: url("../../../public/gouvernance.png");
            }
            > .img.simple {
                background-image: url("../../../public/simple.png");
            }
            > .img.ecologie {
                background-image: url("../../../public/ecologie.png");
            }

            > .content-ctn {
                background-color: white;
                border-radius: 15px; 
                margin-top: -4vh;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding-left: 5%;
                padding-right: 5%;

                > h1 {
                    font-size: 1.2rem;
                    color: #707070;
                    text-align: center;
                }
                > p {
                    font-size: 0.8rem;
                    color: #707070;
                    padding-left: 10%;
                    padding-right: 10%;
                }
            }
        }
    }
}  
@media screen and (min-width: 620px) {
    .Values > .sub-values-ctn > .ctn {
        width: 65%;
    }
}
@media screen and (min-width:900px) {
    .Values > .sub-values-ctn {
        margin-top: 15vh;
        height: 85vh;

        > .ctn {
            flex-direction: row;
            height: 40vh;
            width: 90%;
            position: relative;
            display: flex;
            align-items: center;

            > .content-ctn {
                width: calc(100% - 40vh);
                height: 100%;
                margin-top: 0;
                padding-left: 0;
                padding-right: 0;        
                right: 20px;
                position: absolute;
            }
            > .img.simple,
            > .img.ecologie,
            > .img.gouvernance {
                height: 40vh;
                width: 40vh;
                background-size: 100% 100%;
                position: absolute;
                left: calc(20px + 40vh);
            }
            > .img.simple.current,
            > .img.ecologie.current,
            > .img.gouvernance.current {
                animation: imgValues 1s ease-out forwards;
            }
        }
    }
    @keyframes imgValues {
        0%  {
            left: calc(20px + 40vh);
        }
        100% {
            left: 20px;
        }
    }
    @keyframes back-imgValues {
        0%  {
            left: 20px;
        }
        100% {
            left: calc(20px + 40vh);
        }
    }
}
@media screen and (min-width: 1100px) {
    .Values {
        top: 400vh;

        > .sub-values-ctn > .ctn > .content-ctn > h1 {
            text-align: left;
            padding-left: 10%;
        }
    }
}