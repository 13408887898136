.Team {
    position: absolute;
    height: 100vh;
    top: calc(9* 100vh);
    width: 100%;
    display: flex;
    justify-content: center;

    > .card {
        background-color: white;
        width: 90%;
        border-radius: 25px;
        margin-top: 10%;
        margin-bottom: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 1vh;

        > h3 {
            font-size: 0.9rem;
            color: #707070;
        }
        > .title-ctn {
            display: flex;
            align-items: center;

            > img {
                width: 40px;
                height: 40px;
            }
            > h1 {
                font-size: 25px;
                color: #707070;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        > .photos-ctn {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: flex-start;
            margin-top: 5vh;

            > .single-ctn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-left: 15px;
                margin-right: 15px;

                > img {
                    width: 125px;
                    height: 125px;
                }
                > h3 {
                    color: #707070;
                    margin-top: 2vh;
                }
            }
        }
        > .networks-ctn {
            display: flex;
            width: 70%;
            align-items: center;
            align-content: center;
            flex-direction: row;
            justify-content: space-evenly;
            margin-top: 1vh;

            > a > img {
                width: 40px;
                height: 40px;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }

}
@media screen and (min-width:500px) {
    .Team > .card > .photos-ctn {
        width: 80%;

        >.single-ctn {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
}
@media screen and (min-width: 620px) {
    .Team > .card {
        width: 70%;
        margin-top: 5%;
        margin-bottom: 5%;
        > .photos-ctn > .single-ctn {
            margin-left: 25px;
            margin-right: 25px;
        }
    }
}
@media screen and (min-width: 1100px) {
    .Team {
        top: calc(7* 100vh);
        > .card {
            width: 40%;
            margin-top: 3%;
            margin-bottom: 3%;
        }
    }
}