.Error {
    position: absolute;
    width: 100vw;
    height:100vh;
    background: rgb(242,114,68);
    background: -moz-linear-gradient(237deg, rgba(242,114,68,1) 0%, rgba(217,173,210,1) 25%, rgba(152,210,203,1) 50%, rgba(242,180,126,1) 100%, rgba(130,123,219,1) 100%);
    background: -webkit-linear-gradient(237deg, rgba(242,114,68,1) 0%, rgba(217,173,210,1) 25%, rgba(152,210,203,1) 50%, rgba(242,180,126,1) 100%, rgba(130,123,219,1) 100%);
    background: linear-gradient(237deg, rgba(242,114,68,1) 0%, rgba(217,173,210,1) 25%, rgba(152,210,203,1) 50%, rgba(242,180,126,1) 100%, rgba(130,123,219,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f27244",endColorstr="#827bdb",GradientType=1);
    display: flex;
    justify-content: center;
    align-items: center;
    > .ctn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > h1 {
            color: var(--main-white);
            text-align: center;
        }
    }
}