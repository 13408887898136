
.Cirrus {
    width: 100%;
    height: 102vh;
    position:absolute;
    top:98vh;
    > .cirrus-title {
        width: 100%;
        height: 52vh;
        border-radius: 10px;
        background-image: url("../../../public/aurore.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 100%;
        > h1 {
            margin: 0;
            color:var(--main-white);
            text-align: center;
            font-size: 3rem;
            padding-top: 15vh;
        }
        
        > p {
            margin: 0;
            color:var(--main-white);
            text-align: center;
            padding-top: 15vh;
            width:70%;
            padding-left:15%;
            padding-right:15%;
            font-weight: 500;
        }
    }
    > .green-ctn {
        width: 100%;
        background-color: var(--main-green-two);
        margin-top: -4vh;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        > .grey-ctn {
            width: 80%;
            border-radius: 20px;
            background-color: var(--main-white);
            display: flex;
            padding-left: 5%;
            padding-right: 5%;
            margin-bottom: 1vh;
            flex-direction: column;
            position: relative;
            > .text-ctn {
                height: 29vh;
                display: flex;
                align-items: center;
                padding-left: 5%;
                padding-right: 5%;
                > p {
                    text-align: center;
                    font-size: 14px;
                    color: var(--main-green-one);
                }
            }
            > .sub-green-ctn {
                background-color: var(--main-green-three);
                border-radius: 15px;
                width: 100%;    
                height: 97vh;
                margin-top: 2vh;
                margin-bottom: 1vh;
                display: flex;
                justify-content: center;
                flex-direction: column;

                >.title-ctn {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    > p {
                        font-size: 15px;
                        color: var(--main-white);
                        text-align: center;
                    }
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
                
            }
            > .test-ctn.last {
                height: 98vh;
            }
            > .test-ctn {
                width: 100%;
                height: 100vh;
                position: relative;
                > h3 {
                    font-weight: 600;
                    color: var(--main-violet-one);
                    position: absolute;
                }
                > .emojis {
                    position: absolute;
                    top:0;
                    > .not-animated {
                        animation: back-slideInFromTop 1s ease-out forwards;
                    }
                    > .animated {
                        animation: slideInFromTop 1s ease-out forwards; 
                    }
                    > .one {
                        position: absolute;
                        top: 8vh;
                        left: 0;
                        width: 35px;
                        --rotate-angle:45deg;
                        opacity: 0;
                    }
                    > .two {
                        position: absolute;
                        top: 18vh;
                        left: 64vw;
                        width: 65px;
                        --rotate-angle:-25deg;
                        opacity: 0;
                    }
                    > .three {
                        position: absolute;
                        top: 71vh;
                        left: 16vw;
                        width: 95px;
                        --rotate-angle:31deg;
                        opacity: 0;
                    }
                    > .four {
                        position: absolute;
                        top: 11vh;
                        left: 31vw;
                        width: 36px;
                        --rotate-angle:-93deg;
                        opacity: 0;
                    }
                    > .five {
                        position: absolute;
                        top: 90vh;
                        left: 69vw;
                        width: 48px;
                        --rotate-angle:23deg;
                        opacity: 0;
                    }
                    > .six {
                        position: absolute;
                        top: 1vh;
                        left: 63vw;
                        width: 50px;
                        --rotate-angle:8deg; 
                        opacity: 0;
                    }
                }
            }
        }
        
        > img {
            height: 15vh;
            margin-top: 5vh;
            margin-bottom: 5vh;
        }
    }
}

@keyframes slideInFromTop {
    0% {
      transform: translateY(-15vh) rotate(0);
      opacity: 0;
    }
    100% {
      transform: translateY(0) rotate(var(--rotate-angle));
      opacity: 1;
    }
}
@keyframes back-slideInFromTop {
    0% {
        transform: translateY(0) rotate(var(--rotate-angle));
        opacity: 1;
    }
    100% {
      transform: translateY(-15vh) rotate(0);
      opacity: 0;
    }
}
@media screen and (min-width: 620px) {
    .Cirrus {
        > .cirrus-title {
            background-size: 100% 100%;
            border-radius: 20px;
            width: 90%;
            margin-left: 5%;
        }
        > .green-ctn {
            width: 90%;
            margin-left: 5%;
        }
    }
}
@media screen and (min-width:900px) {
    .Cirrus {
        > .cirrus-title {
            margin-top: 7vh;
            background-size: 100% 100vh ;
            border-radius: 0;
            animation: back-openAurore 1s ease-out forwards; 
        }
        > .cirrus-title.current {
            margin-top: 7vh;
            background-size:  100% 100vh;
            border-radius: 0;
            animation: openAurore 1s ease-out forwards; 
        }
        > .green-ctn.current {
            animation: bigger 1s ease-out forwards; 
            > .grey-ctn {
                width:90%;
                padding-left: 2%;
                padding-right:2%;
                > .sub-green-ctn {
                    height: 80vh;
                    margin-top: 5vh;
                }
            }
        }
        > .green-ctn  {
            margin-top: 54vh;
            position: absolute;
            top: 0;
            animation: backbigger 1s ease-out forwards; 
            > .grey-ctn {
                > .text-ctn {
                    display: flex;
                    align-content: center;
                    align-items: center;
                    flex-direction: column;
                    > p {
                        font-size: 20px;
                    }
                    > .emojis {
                        width: 100%;
                    }
                    > h3 {
                        font-size: 1.6rem;
                    }
                }
                > .test-ctn.last {
                    height: 91vh;
                }
                > .sub-green-ctn {
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: 70vh;
                    margin-top: 10vh;
                    margin-bottom: 20vh;
                    > .title-ctn {
                        > p{
                            font-size: 24px;
                        }
                    }
                    
                }
            } 
        }
    }
    @keyframes bigger {
        0%  {
            margin-left: 5%;
            width: 90%;
        }
        100% {
            width: 100%;
            margin-left: 0;
        }
    }
    @keyframes backbigger {
        0%  {
            width: 100%;
            margin-left: 0;
        }
        100% {
            margin-left: 5%;
            width: 90%;
        }
    }
    @keyframes openAurore {
        0%  {
            width: 90%;
            margin-left: 5%;
            height: 52vh;
            border-radius:20px;
        }
        100% {
            width: 100%;
            height: 95vh;
            margin-left: 0%;
            border-radius:0;
        }
    }
    @keyframes back-openAurore {
        0% {
            width: 100%;
            height: 95vh;
            margin-left: 0%;
            border-radius:0;
        }
        100% {
            width: 90%;
            margin-left: 5%;
            height: 52vh;
            border-radius:20px;
        }
    }
}
@media screen and (min-width: 1100px) {
    .Cirrus > .green-ctn > .grey-ctn {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        > .test-ctn {
            width: 30%;
            height: 90vh;
        }

    }
}