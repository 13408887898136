.Home {
    width: 100%;
    height: 100vh;
    position: absolute;
    top:0;
    > .title {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 15vh;
        align-items: flex-start;
        > img {
            width: 55px;
        }
        > h1 {
            color: white;
            margin: 0;
            padding-left: 10px;
            font-size: 45px;
            padding-top: 5px;
        }
    }
    > .subtitle {
        width: 70%;
        padding-left: 15%;
        padding-right: 15%;
        margin-top: 10vh;
        > p {
            color: white;
            margin: 0;
            text-align: center;
        }
    }
    > ul.nav-ctn {
        list-style: none;
        text-align: right;
        top: 73vh;
        right: 5vw;
        position: absolute;
        padding: 0;
        margin: 0;
        > li {
            color: white;
            height: 5vh;
            
        }
        > li:hover {
            color: white;
            text-decoration: underline;
        }
    }
    > .c-ctn {
        position: relative;
        > .cmenu {
            width: 7px;
            height: 14px;
            background-color: white;
            border-radius: 100%;
            border-radius: 7px 0 0 7px;
            position: absolute;
            right: 0;
        }
        > .cmenu.first {
            top: 73.5dvh;
        }
        > .cmenu.second {
            top: 78.5vh;
        }
        > .cmenu.third {
            top: 83.5vh;
        }
        > .c1 {
            width: 35px;
            height: 70px;
            background-color: white;
            border-radius: 35px 0 0 35px;
            position: absolute;
            right: 0;
            top: 39vh;
        }
        > .c2 {
            width: 35px;
            height: 35px;
            background-color: white;
            border-radius: 100%;
            position: absolute;
            right: 26px;
            top: 52vh;
        }
        > .c3 {
            width: 15px;
            height: 15px;
            background-color: white;
            border-radius: 100%;
            position: absolute;
            right: 81px;
            top: 46dvh;
        }
    }
}
@media screen and (min-width:900px) {
    .Home {
        > ul.nav-ctn {
            display: none;
        }
        > .title {
            margin-top: 35vh;
            > h1 {
                padding-left: 0;
                font-size: 4rem;
            }
            > img {
                display: none;
            }
        }
        > .subtitle > p {
            font-size: 1.3rem;
        }
        > .c-ctn { 
            > .cmenu.first,
            > .cmenu.second,
            > .cmenu.third {
                display: none;
            }
            > .c1 {
                width: 80px;
                height: 160px;
                border-radius: 80px 0 0 80px;
                top: 60vh;
            }
            > .c2 {
                width: 80px;
                height: 80px;
                right: 95px;
                top: 80vh;
            }
            > .c3 {
                width: 30px;
                height: 30px;
                right: 230px;
                top: 68vh;
            }
        }

    }

}