.navbar-ctn {
    display: none;
} 
.laptop-logo {
    display: none;
}
@media screen and (min-width:900px) {
    .navbar-ctn {
        display: flex;
        background-color: white;
        width: 100%;
        height: 8vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-shadow: -3px -5px 8px 1px #707070;
        > h3 {
            color: #707070;
            margin-left: 12vw;
            font-size: 0.9rem;
        }
        > ul.nav-ctn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 40%;
            margin-right: 2%;
            list-style: none;
            > li {
                color: #707070;
                cursor: pointer;
            }
            > li.current {
                font-weight: 600;
                text-decoration: underline;
            }
        }

    }
    .navbar-ctn.little {
        height: 5vh;
        justify-content: flex-end;
        h3 {
            display: none;
        }
    }
    .laptop-logo {
        display: block;
        position: fixed;
        top: 1vh;
        z-index: 99;
        border-radius: 100%;
        border: 5px solid white;
        width: 6vw;
        left: 2vw;
        animation: back-logo 0.5s ease-in-out forwards;
    }
    .laptop-logo.little {
        display: block;
        position: fixed;
        z-index: 99;
        border-radius: 100%;
        border: 2px solid white;        
        width: 2vw;
        left: 2vw;
        animation: logo 0.5s ease-in-out forwards;
    }
   
    @keyframes logo {
        0% {
            display: block;
            position: fixed;
            top: 1vh;
            z-index: 99;
            border-radius: 100%;
            border: 5px solid white;
            width: 6vw;
            left: 2vw;
        }
        100% {
            display: block;
            position: fixed;
            z-index: 99;
            border-radius: 100%;
            border: 2px solid white;        
            width: 2vw;
            left: 2vw;
        }
    }
    @keyframes back-logo {
        0% {
            display: block;
            position: fixed;
            z-index: 99;
            border-radius: 100%;
            border: 2px solid white;        
            width: 2vw;
            left: 2vw;
        }
        100% {
            display: block;
            position: fixed;
            top: 1vh;
            z-index: 99;
            border-radius: 100%;
            border: 5px solid white;
            width: 6vw;
            left: 2vw;
        }
    }
}
